import { GrantApplicationStatus } from "@/shared/enums/GrantApplicationStatus.enum";

export const grantApplicationStatusMap: Record<GrantApplicationStatus, string> = {
  [GrantApplicationStatus.None]: "Søknaden er ikke sendt",
  [GrantApplicationStatus.SentForApproval]: "Søknad til behandling",
  [GrantApplicationStatus.BudgetApproved]: "Søknadsbeløpet er godkjent",
  [GrantApplicationStatus.CostAdded]: "Faktiske kostnadeer er lagt til",
  [GrantApplicationStatus.CostApproved]: "Faktiske kostnader er godkjent",
  [GrantApplicationStatus.Rejected]: "Søknaden er avslått",
} as const;
