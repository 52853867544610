var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.facilitationGrantMap ? _c('BaseLayout', {
    staticClass: "px-0",
    attrs: {
      "display-tabs": ""
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('v-icon', {
          staticClass: "mr-2",
          on: {
            "click": _vm.navigateToDashboard
          }
        }, [_vm._v(" mdi-arrow-left")]), _vm._v(" Tilretteleggingstilskudd ")];
      },
      proxy: true
    }, {
      key: "tabs",
      fn: function fn() {
        return [_c('v-tabs', {
          model: {
            value: _vm.currentFilter,
            callback: function callback($$v) {
              _vm.currentFilter = $$v;
            },
            expression: "currentFilter"
          }
        }, _vm._l(_vm.tabs, function (_ref) {
          var icon = _ref.icon,
              label = _ref.label,
              filter = _ref.filter;
          return _c('v-tab', {
            key: label,
            attrs: {
              "href": "#".concat(filter)
            }
          }, [_c('v-icon', {
            attrs: {
              "left": ""
            }
          }, [_vm._v(_vm._s(icon))]), _vm._v(" " + _vm._s(label) + " "), _vm.facilitationGrantMap[filter] ? _c('v-chip', {
            staticClass: "ml-2",
            attrs: {
              "outlined": "",
              "color": "white",
              "pill": "",
              "x-small": ""
            },
            domProps: {
              "textContent": _vm._s(_vm.facilitationGrantMap[filter].length)
            }
          }) : _vm._e()], 1);
        }), 1)];
      },
      proxy: true
    }], null, false, 3793611638)
  }, [[_c('BaseTableFiltered', {
    attrs: {
      "headers": _vm.headers,
      "items": _vm.facilitationGrantMap[_vm.currentFilter]
    },
    scopedSlots: _vm._u([{
      key: "item.applicationStatus",
      fn: function fn(_ref2) {
        var value = _ref2.value;
        return [_vm._v(" " + _vm._s(_vm.grantApplicationStatusMap[value]) + " ")];
      }
    }, {
      key: "actions",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref4) {
              var on = _ref4.on,
                  attrs = _ref4.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "icon": "",
                  "color": "primary",
                  "to": _vm.getFacilitationGrantLink(item.courseId)
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-open-in-new")])], 1)];
            }
          }], null, true)
        }, [_c('span', [_vm._v("Vis søknad")])])];
      }
    }], null, true)
  })]], 2) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }